import React from 'react';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from './loadingSpinner';
import FormInput from './formInput';
import { useState } from 'react';
import { validateImei, validateCustomerNr } from '../utils/validation';

interface IStarterForm {
  currentLanguage: string;
  imei?: string;
  setImei: React.Dispatch<React.SetStateAction<string>>;
  customerNr?: string;
  setCustomerNr: React.Dispatch<React.SetStateAction<string>>;
  handleImeiCheck: (imei: string) => void;
  loading: boolean;
  checkSuccessfull: boolean;
}

const StarterForm: React.FC<IStarterForm> = ({
  currentLanguage,
  imei,
  setImei,
  customerNr,
  setCustomerNr,
  handleImeiCheck,
  loading,
  checkSuccessfull
}) => {
  //Form Validation State
  const [imeiValidated, setImeiValidated] = useState<boolean>(true);
  const [customerNrValidated, setCustomerNrValidated] = useState<boolean>(true);
  const [imeiEmpty, setImeiEmpty] = useState<boolean>(false);
  const [customerNrEmpty, setCustomerNrEmpty] = useState<boolean>(false);
  const [starterFormValidated, setStarterFormValidated] =
    useState<boolean>(true);

  const { t } = useTranslation();

  const getFormImeiSmallprintLink = () => {
    return 'img/lang/' + currentLanguage + '/imei.jpg';
  };

  const getFormCustomerSmallprintLink = () => {
    return 'img/lang/' + currentLanguage + '/customerid.jpg';
  };

  const handleFormSubmit = (e: any) => {
    let formValid: boolean = true;

    let imeiValid: boolean = true;
    let customerNrValid: boolean = true;

    e.preventDefault();
    if (customerNr && imei) {
      formValid = true;
      setStarterFormValidated(true);

      imeiValid = validateImei(imei).validated;
      setImeiValidated(imeiValid);

      customerNrValid = validateCustomerNr(customerNr).validated;
      setCustomerNrValidated(customerNrValid);
    } else {
      formValid = false;
      setStarterFormValidated(false);
      if (!customerNr) {
        setCustomerNrEmpty(true);
      }
      if (!imei) {
        setImeiEmpty(true);
      }
    }

    if (!imeiValid || !customerNrValid) {
      formValid = false;
      setStarterFormValidated(false);
    }

    if (formValid === true && imei) {
      handleImeiCheck(imei);
    }
  };

  return (
    <form
      id="registration"
      className="uk-padding-large uk-grid-small"
      uk-grid="true"
    >
      <div className="uk-width-1-1">
        <h3>
          <b>{t('form.title_cloud')}</b>
        </h3>
      </div>

      <div className="uk-width-1-1">
        <label htmlFor="form-imei">
          {t('form.imei.label')}
          <div uk-lightbox="true">
            <a
              href={getFormImeiSmallprintLink()}
              className="uk-link-muted"
              data-caption={t('form.imei.smallprint')}
            >
              <small className="uk-text-muted">
                {t('form.imei.smallprint')}
              </small>
            </a>
          </div>
        </label>
        <div className="uk-margin">
          <FormInput
            setEmpty={setImeiEmpty}
            isEmpty={imeiEmpty}
            input={imei}
            isValidated={imeiValidated}
            setValidated={setImeiValidated}
            validationFct={validateImei}
            id="form-imei"
            name="imei"
            type="text"
            className="
                      uk-input
                      as-input
                      uk-form-large
                      uk-text-center
                      uk-text-small
                      uk-border-rounded
                      uk-background-muted
                    "
            placeholder={t('form.imei.placeholder')}
            autoComplete="off"
            required
            minLength={15}
            maxLength={18}
            onChange={(e: any) => setImei(e.currentTarget.value)}
          />
        </div>
      </div>

      <div className="uk-width-1-1">
        <label htmlFor="form-customer-id">
          {t('form.customer.label')}
          <div uk-lightbox="true">
            <a
              href={getFormCustomerSmallprintLink()}
              className="uk-link-muted"
              data-caption={t('form.customer.smallprint')}
            >
              <small className="uk-text-muted">
                {t('form.customer.smallprint')}
              </small>
            </a>
          </div>
        </label>
        <div className="uk-margin">
          <FormInput
            setEmpty={setCustomerNrEmpty}
            isEmpty={customerNrEmpty}
            isValidated={customerNrValidated}
            setValidated={setCustomerNrValidated}
            validationFct={validateCustomerNr}
            input={customerNr}
            id="form-customer-id"
            name="customerId"
            type="text"
            className="
                    uk-input
                    as-input
                    uk-form-large
                    uk-text-center
                    uk-text-small
                    uk-border-rounded
                    uk-background-muted
                  "
            placeholder={t('form.customer.placeholder')}
            autoComplete="off"
            required
            minLength={7}
            maxLength={7}
            onChange={(e: any) => setCustomerNr(e.currentTarget.value)}
          />
        </div>
      </div>

      {checkSuccessfull ? (
        <></>
      ) : (
        <div className="uk-width-1-1 uk-margin-medium-top">
          <div className="uk-center-text">
            <button
              type="submit"
              className="
                      uk-button uk-button-primary
                      as-button-primary
                      uk-button-large uk-border-rounded
                      as-hide-if-loading
                    "
              onClick={(e) => handleFormSubmit(e)}
            >
              {loading ? <LoadingSpinner /> : <p>{t('check_data')}</p>}
            </button>
          </div>
          {starterFormValidated ? (
            <div></div>
          ) : (
            <label className="uk-text-danger" style={{ fontSize: '80%' }}>
              {t('errors.form')}
            </label>
          )}
        </div>
      )}
    </form>
  );
};

export default StarterForm;
