import React, { useState } from 'react';
import './App.css';
import { useTranslation } from 'react-i18next';
import NavBar from './components/navbar';
import StarterForm from './components/starterForm';
import ErrorModal from './components/errorModal';
import SuccessModalApollo from './components/successModalApollo';
import SuccessModalCumulocity from './components/successModalCumulocity';
import ImprintModal from './components/imprintModal';
import FormLogic from './components/formLogic';

export interface IApolloBody {
  imei: String;
  customerId: String;
  domain: String;
  apolloId: String;
  language: String;
}

export interface ICumulocityBody {
  imei: string;
  customerId: string;
  domain: string;
  email: string;
  salutation: string;
  firstName: string;
  lastName: string;
  company: string;
  phone: string;
  street: string;
  postcode: string;
  city: string;
  industry: string;
  password: string;
  language: string;
}

function App() {
  const { t } = useTranslation();
  const [currentLanguage, setLanguage] = useState<string>('en');
  const [imprintIsOpen, setImprintOpen] = useState<boolean>(false);
  const [imeiCheckSuccessfull, setImeiCheckSuccessfull] =
    useState<boolean>(false);

  const [type, setType] = useState<string | null>('initial');
  const [loading, setLoading] = useState<boolean>(false);
  const [showSuccessModalApollo, setShowSuccessModalApollo] =
    useState<boolean>(false);
  const [showSuccessModalCumulocity, setShowSuccessModalCumulocity] =
    useState<boolean>(false);

  //Error modal
  const [errorModalIsOpen, setErrorModalOpen] = useState<boolean>(false);
  const errorMessageHeading = t('errors.heading');
  const [errorMessageBody, setErrorMessageBody] = useState<string>('');

  //State starter form
  const [url, setUrl] = useState<string>('');
  const [imei, setImei] = useState<string>('');
  const [customerNr, setCustomerNr] = useState<string>('');

  //State contact form
  const [salutation, setSalutation] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [company, setCompany] = useState<string>('');
  const [industry, setIndustry] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [street, setStreet] = useState<string>('');
  const [postcode, setPostcode] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordConfirm, setPasswordConfirm] = useState<string>('');
  const [acceptToc, setAcceptToc] = useState<boolean>(false);
  const [acceptNewsletter, setAcceptNewsletter] = useState<boolean>(false);

  //State apollo form
  const [apolloId, setApolloId] = useState<string>('');

  const handleNewsletterSubscription = async () => {
    try {
      if (process.env.REACT_APP_NEWS_URL) {
        let res = await fetch(
          process.env.REACT_APP_NEWS_URL +
            '/api/v3.3/subscribers/listid.xmljson',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              EmailAddress: email,
              Name: firstName + ' ' + lastName,
              Resubscribe: false,
              RestartSubscriptionBasedAutoresponders: false,
              ConsentToTrack: 'No'
            })
          }
        );

        if (!res.ok) {
          console.error('Error subscribing to Newsletter');
          console.error('Response', res);
        } else {
          console.log('Successfully subscribed to Newsletter');
        }
      }
    } catch (error) {
      console.error('Error subscribing to Newsletter');
      console.error(error);
    }
  };

  const handleImeiCheck = async (imei: string) => {
    setLoading(true);
    try {
      let res = await fetch(
        `/api/v1/deviceType?imei=` + imei + '&customerId=' + customerNr
      );

      setLoading(false);
      if (!res.ok) {
        if (res.status === 400) {
          setErrorMessageBody(t('errors.form'));
          setErrorModalOpen(true);
        } else if (res.status === 500) {
          setErrorMessageBody(t('errors.500'));
          setErrorModalOpen(true);
        } else {
          setErrorMessageBody(t('errors.general'));
          setErrorModalOpen(true);
        }
      } else {
        res = await res.json();
        setType(res.type);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setErrorMessageBody(t('errors.general'));
      setErrorModalOpen(true);
    }
  };

  const handleFormSubmit = async (body: IApolloBody | ICumulocityBody) => {
    setLoading(true);
    try {
      let res = await fetch('/api/v1/provision', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      });
      setLoading(false);
      if (res.status === 299) {
        setErrorMessageBody(t('confirmation_pending'));
        setErrorModalOpen(true);
      } else if (!res.ok) {
        if (res.status === 400) {
          setErrorMessageBody(t('errors.form'));
          setErrorModalOpen(true);
        } else if (res.status === 500) {
          setErrorMessageBody(t('errors.500'));
          setErrorModalOpen(true);
        } else if (res.status === 404) {
          setErrorMessageBody(t('errors.apollo_id'));
          setErrorModalOpen(true);
        } else {
          setErrorMessageBody(t('errors.general'));
          setErrorModalOpen(true);
        }
      } else {
        //@ts-ignore
        if (body.firstName) {
          setShowSuccessModalCumulocity(true);
        } else {
          setShowSuccessModalApollo(true);
        }
        // delete imei if apollo iokey so that a new one can be registered
        if (type === 'apollo') {
          setImei('');
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setErrorMessageBody(t('errors.general'));
      setErrorModalOpen(true);
    }
  };

  return (
    <>
      <div
        className="uk-offcanvas-content"
        style={{ backgroundColor: '#111a23' }}
      >
        <div className="uk-section uk-padding-remove-vertical">
          <div
            className="
            uk-container uk-container-medium
            as-container-medium as-background-primary
            uk-padding-small
            uk-padding-remove-bottom
            uk-padding-remove-horizontal
          "
            data-src="img/bg_s1.jpg"
            uk-img="true"
            style={{
              backgroundPosition: 'center bottom',
              backgroundSize: '1920px auto',
              backgroundImage:
                'url("https://start.autosen.cloud/img/bg_s1.jpg")'
            }}
          >
            <NavBar
              setLanguage={setLanguage}
              currentLanguage={currentLanguage}
            />
          </div>
        </div>

        <div className="uk-section uk-padding-remove-vertical">
          <div
            className="
            uk-container
            as-container-medium
            uk-padding-remove-horizontal
            as-background-primary
          "
            data-src="img/bg_s1.jpg"
            uk-img="true"
            style={{
              backgroundPosition: 'center -416px',
              backgroundSize: '1920px auto',
              paddingTop: '95px',
              marginTop: '-95px'
            }}
          >
            <div
              className="
              uk-grid-match
              uk-flex-middle
              uk-flex-center
              uk-margin-large-top
              uk-margin-medium-bottom
              uk-grid-collapse
            "
              uk-grid="true"
            >
              <div>
                <img
                  alt=""
                  style={{ width: '90%', height: '90%' }}
                  src="img/iokey.png"
                />
              </div>
            </div>

            <div className="uk-text-center" style={{ padding: '0px 1%' }}>
              <h2 className="as-text-default">{}</h2>
              <p className="as-text-default as-text-large as-text-light">
                {t('hero.title')}
              </p>
              <div
                className="uk-margin-medium-top"
                style={{ marginBottom: '56px' }}
              >
                <img src="img/logo.png" alt="" />
                &nbsp;&nbsp;&nbsp;
                <a
                  href="#register"
                  className="
                  uk-button uk-button-default uk-button-large
                  as-button-primary as-button-outline
                  uk-border-rounded
                "
                  uk-scroll="true"
                >
                  <span className="as-text-medium">{t('hero.activate')}</span>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="uk-section uk-padding-remove-vertical">
          <div
            className="
            uk-container
            as-container-medium
            uk-padding-small uk-padding-remove
            as-background-primary
          "
            style={{ backgroundColor: '#1c2632' }}
            uk-img="true"
          >
            <div
              className="uk-flex-middle uk-flex-center"
              style={{ marginTop: '50px' }}
            >
              <h4
                className="as-text-default uk-text-center uk-margin-remove"
                style={{ padding: '0px 2% !important' }}
                dangerouslySetInnerHTML={{ __html: t('steps.title') }}
              ></h4>
            </div>

            <div
              className="
              uk-flex
              uk-flex-center
              uk-flex-middle
              uk-text-center
              uk-grid-collapse
            "
              uk-grid="true"
            >
              <div>
                <div className="as-card">
                  <img src="img/step1.png" alt="" />
                  <div className="as-card-body">
                    <h1
                      className="
                      as-card-title
                      uk-border-circle uk-inline uk-margin-remove
                    "
                    >
                      {t('steps.one.title')}
                    </h1>
                    <h4 className="as-card-subtitle as-text-default uk-margin-small">
                      {t('steps.one.subtitle')}
                    </h4>
                    <p
                      className="
                      as-text-default as-card-text
                      uk-margin-remove uk-margin-top-small
                    "
                      dangerouslySetInnerHTML={{ __html: t('steps.one.text') }}
                    ></p>
                  </div>
                </div>
              </div>
              <div>
                <div className="as-card">
                  <img src="img/step2.png" alt="" />
                  <div className="as-card-body">
                    <h1
                      className="
                      as-card-title
                      uk-border-circle uk-inline uk-margin-remove
                    "
                    >
                      {t('steps.two.title')}
                    </h1>
                    <h4 className="as-card-subtitle as-text-default uk-margin-small">
                      {t('steps.two.subtitle')}
                    </h4>
                    <p
                      className="
                      as-text-default as-card-text
                      uk-margin-remove uk-margin-top-small
                    "
                      dangerouslySetInnerHTML={{ __html: t('steps.two.text') }}
                    ></p>
                  </div>
                </div>
              </div>
              <div>
                <div className="as-card">
                  <img src="img/step3.png" alt="" />
                  <div className="as-card-body">
                    <h1
                      className="
                      as-card-title
                      uk-border-circle uk-inline uk-margin-remove
                    "
                    >
                      {t('steps.three.title')}
                    </h1>
                    <h4 className="as-card-subtitle as-text-default uk-margin-small">
                      {t('steps.three.subtitle')}
                    </h4>
                    <p
                      className="
                      as-text-default as-card-text
                      uk-margin-remove uk-margin-top-small
                    "
                      dangerouslySetInnerHTML={{
                        __html: t('steps.three.text')
                      }}
                    ></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="register" className="uk-section uk-padding-remove-vertical">
          <div
            className="
            uk-container
            as-container-medium
            uk-padding-remove
            as-background-primary
            uk-position-relative
          "
            style={{ backgroundColor: '#1c2632' }}
            uk-img="true"
          >
            <div
              style={{
                position: 'absolute',
                top: '231px',
                left: '0',
                right: '0',
                bottom: '0',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center'
              }}
              data-src="img/bg_s3.jpg"
              uk-img="true"
            ></div>

            <div
              className="
              uk-container uk-container-small uk-margin-small-top uk-text-center
              as-text-dark
              uk-border-rounded uk-background-default uk-position-relative
            "
              style={{ zIndex: '1' }}
            >
              <StarterForm
                currentLanguage={currentLanguage}
                setImei={setImei}
                setCustomerNr={setCustomerNr}
                handleImeiCheck={handleImeiCheck}
                imei={imei}
                loading={loading}
                customerNr={customerNr}
                checkSuccessfull={imeiCheckSuccessfull}
              />
              <FormLogic
                handleNewsletterSubscription={handleNewsletterSubscription}
                imei={imei}
                customerId={customerNr}
                email={email}
                password={password}
                passwordConfirm={passwordConfirm}
                loading={loading}
                setSalutation={setSalutation}
                setFirstName={setFirstName}
                setLastName={setLastName}
                setEmail={setEmail}
                setCompany={setCompany}
                setIndustry={setIndustry}
                setPhone={setPhone}
                setStreet={setStreet}
                setPostcode={setPostcode}
                setCity={setCity}
                setPassword={setPassword}
                setPasswordConfirm={setPasswordConfirm}
                setAcceptToc={setAcceptToc}
                setAcceptNewsletter={setAcceptNewsletter}
                acceptToc={acceptToc}
                acceptNewsletter={acceptNewsletter}
                type={type}
                setErrorModalOpen={setErrorModalOpen}
                setErrorMessageBody={setErrorMessageBody}
                t={t}
                setType={setType}
                setImeiCheckSuccessfull={setImeiCheckSuccessfull}
                setApolloId={setApolloId}
                handleFormSubmit={handleFormSubmit}
                apolloId={apolloId}
                salutation={salutation}
                firstName={firstName}
                lastName={lastName}
                company={company}
                industry={industry}
                phone={phone}
                street={street}
                postcode={postcode}
                city={city}
                currentLanguage={currentLanguage}
                url={url}
                setUrl={setUrl}
              />
            </div>
          </div>
        </div>

        <div className="uk-section uk-padding-remove-vertical">
          <div
            className="
            uk-container
            as-container-medium
            uk-padding-remove
            as-background-primary
          "
          >
            <div className="uk-padding uk-flex uk-flex-center">
              <button
                style={{ background: 'none', border: 'none' }}
                className="as-link as-text-default uk-margin-small-right"
                onClick={() => setImprintOpen(true)}
              >
                {t('footer.site_notice.label')}
              </button>
              <a
                href={t('footer.privacy.customurl')}
                data-title={t('footer.privacy.title')}
                className="as-link as-text-default uk-margin-small-right"
                target="_blank"
                rel="noreferrer"
              >
                {t('footer.privacy.label')}
              </a>
              <a
                href={t('footer.terms_and_conditions.customurl')}
                data-title={t('footer.terms_and_conditions')}
                className="as-link as-text-default uk-margin-small-right"
                target="_blank"
                rel="noreferrer"
              >
                {t('footer.terms_and_conditions.label')}
              </a>
              <a
                href={t('footer.ats_eula.customurl')}
                data-title={t('footer.ats_eula')}
                className="as-link as-text-default uk-margin-small-right"
                target="_blank"
                rel="noreferrer"
              >
                {t('footer.ats_eula.label')}
              </a>
              <a
                href={t('footer.ats_foss_apollo.customurl')}
                data-title={t('footer.ats_foss_apollo')}
                className="as-link as-text-default uk-margin-small-right"
                target="_blank"
                rel="noreferrer"
              >
                {t('footer.ats_foss_apollo.label')}
              </a>
              <a
                href={t('footer.ats_foss_iokey.customurl')}
                data-title={t('footer.ats_foss_iokey')}
                className="as-link as-text-default"
                target="_blank"
                rel="noreferrer"
              >
                {t('footer.ats_foss_iokey.label')}
              </a>
            </div>
          </div>
        </div>
      </div>

      <ImprintModal
        imprintIsOpen={imprintIsOpen}
        setImprintOpen={setImprintOpen}
        t={t}
      />

      <ErrorModal
        errorModalIsOpen={errorModalIsOpen}
        setErrorModalOpen={setErrorModalOpen}
        t={t}
        errorMessageBody={errorMessageBody}
        errorMessageHeading={errorMessageHeading}
      />

      <SuccessModalApollo
        t={t}
        showSuccessModalApollo={showSuccessModalApollo}
        setShowSuccessModalApollo={setShowSuccessModalApollo}
      />

      <SuccessModalCumulocity
        t={t}
        showSuccessModalCumulocity={showSuccessModalCumulocity}
        setShowSuccessModalCumulocity={setShowSuccessModalCumulocity}
        domain={url}
      />
    </>
  );
}

export default App;
