import React from 'react';
import Modal from 'react-modal';

interface ISuccessModalCumulocity {
  t: any;
  showSuccessModalCumulocity: boolean;
  setShowSuccessModalCumulocity: React.Dispatch<React.SetStateAction<boolean>>;
  domain: string;
}

const SuccessModal: React.FC<ISuccessModalCumulocity> = ({
  t,
  showSuccessModalCumulocity,
  setShowSuccessModalCumulocity,
  domain
}) => {
  let content: string = t('confirmation.body');
  content = content.replace(
    '%url',
    `<a href="https://${domain}.autosen.cloud/">https://${domain}.autosen.cloud/</a>`
  );
  content = content.replace(
    'https://help.io-key.com',
    '<a href="https://help.io-key.com">https://help.io-key.com</a>'
  );

  return (
    <Modal
      isOpen={showSuccessModalCumulocity}
      overlayClassName="Overlay"
      className="react-modal-success-cumulocity"
      ariaHideApp={false}
    >
      <div>
        <button
          className="modal-close-button uk-modal-close-default uk-close uk-icon"
          type="button"
          onClick={() => setShowSuccessModalCumulocity(false)}
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              fill="none"
              stroke="#000"
              strokeWidth="1.1"
              x1="1"
              y1="1"
              x2="13"
              y2="13"
            ></line>
            <line
              fill="none"
              stroke="#000"
              strokeWidth="1.1"
              x1="13"
              y1="1"
              x2="1"
              y2="13"
            ></line>
          </svg>
        </button>
        <div className="uk-modal-header">
          <h2 className="uk-modal-title">{t('success_thankyou')}</h2>
        </div>
        <div
          className="uk-modal-body react-modalcontent"
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
        <div className="uk-modal-footer uk-text-center">
          <button
            className="uk-button uk-button-primary
            as-button-primary
             uk-button-large uk-border-rounded uk-margin-small-right"
            onClick={() => setShowSuccessModalCumulocity(false)}
          >
            {t('success_close')}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default SuccessModal;
