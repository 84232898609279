import React from 'react';
import { useTranslation } from 'react-i18next';

interface INavBar {
  setLanguage: React.Dispatch<React.SetStateAction<string>>;
  currentLanguage?: String;
}

const NavBar: React.FC<INavBar> = ({ setLanguage, currentLanguage }) => {
  const { t, i18n } = useTranslation();

  const handleLanguageChange = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    code: string
  ) => {
    e.preventDefault();
    setLanguage(code);
    i18n.changeLanguage(code);
  };

  const getLanguageImageLink = () => {
    return `img/lang/` + currentLanguage + '/flag.png';
  };

  return (
    <>
      <nav
        className="uk-navbar-container uk-navbar-transparent"
        uk-navbar="true"
      >
        <div
          className="uk-navbar-left uk-margin-small-left-"
          style={{ marginLeft: '20px' }}
        >
          <a
            className="uk-navbar-item uk-logo"
            href="{{ path_for('index', { 'lang': lang }) }}"
          >
            <img src="img/logo.png" alt="autosen Cloud" />
          </a>
        </div>

        <div className="uk-navbar-right uk-visible@m">
          <div className="uk-margin-medium-right"></div>
          <div className="uk-margin-medium-right">
            <a
              href="#register"
              className="
                    uk-button uk-button-default
                    as-button-primary as-button-outline as-button-small
                    uk-border-rounded
                  "
              uk-scroll="true"
            >
              {t('hero.activate')}
            </a>
          </div>
          <div className="uk-margin-medium-right">
            <img
              src={getLanguageImageLink()}
              alt="Deutsch"
              width="32"
              height="18"
            />
            <div
              uk-dropdown="pos: bottom-right;"
              style={{
                minWidth: '250px',
                boxShadow: 'none',
                background: 'transparent'
              }}
            >
              <button
                style={{ background: 'none', border: 'none' }}
                onClick={(e) => handleLanguageChange(e, 'de')}
                className="uk-margin-small-right uk-transition-toggle"
                data-test="lang-de"
              >
                <img
                  className="uk-transition-scale-up uk-transition-opaque"
                  src="img/lang/de/flag.png"
                  alt="Deutsch"
                />
              </button>
              <button
                style={{ background: 'none', border: 'none' }}
                onClick={(e) => handleLanguageChange(e, 'en')}
                className="uk-margin-small-right uk-transition-toggle"
              >
                <img
                  className="uk-transition-scale-up uk-transition-opaque"
                  src="img/lang/en/flag.png"
                  alt="English"
                />
              </button>
              <button
                style={{ background: 'none', border: 'none' }}
                onClick={(e) => handleLanguageChange(e, 'fr')}
                className="uk-margin-small-right uk-transition-toggle"
              >
                <img
                  className="uk-transition-scale-up uk-transition-opaque"
                  src="img/lang/fr/flag.png"
                  alt="Français"
                />
              </button>
              <button
                style={{ background: 'none', border: 'none' }}
                onClick={(e) => handleLanguageChange(e, 'it')}
                className="uk-transition-toggle"
              >
                <img
                  className="uk-transition-scale-up uk-transition-opaque"
                  src="img/lang/it/flag.png"
                  alt="Italiano"
                />
              </button>
              <button
                style={{
                  background: 'none',
                  border: 'none',
                  marginLeft: '10px'
                }}
                onClick={(e) => handleLanguageChange(e, 'cz')}
                className="uk-transition-toggle"
              >
                <img
                  className="uk-transition-scale-up uk-transition-opaque"
                  src="img/lang/cz/flag.png"
                  alt="Česky"
                />
              </button>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavBar;
