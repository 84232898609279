import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

//import LanguageDetector from 'i18next-browser-languagedetector';
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

import translationDE from './translations/locales/de/translationDE.json';
import translationCZ from './translations/locales/cz/translationCZ.json';
import translationEN from './translations/locales/en/translationEN.json';
import translationFR from './translations/locales/fr/translationFR.json';
import translationIT from './translations/locales/it/translationIT.json';

const resources = {
  de: {
    translation: translationDE
  },
  cz: {
    translation: translationCZ
  },
  en: {
    translation: translationEN
  },
  fr: {
    translation: translationFR
  },
  it: {
    translation: translationIT
  }
};

i18n

  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  //.use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: 'en',
    resources,
    fallbackLng: 'en',
    debug: true,
    returnObjects: true,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  });

export default i18n;
