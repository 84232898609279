import React from 'react';
import Modal from 'react-modal';

interface IErrorModal {
  errorModalIsOpen: boolean;
  setErrorModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  t: any;
  errorMessageBody: string;
  errorMessageHeading: string;
}

const ErrorModal: React.FC<IErrorModal> = ({
  errorModalIsOpen,
  setErrorModalOpen,
  errorMessageBody,
  errorMessageHeading,
  t
}) => {
  return (
    <Modal
      isOpen={errorModalIsOpen}
      overlayClassName="Overlay"
      className="react-modal-success"
      ariaHideApp={false}
    >
      <div>
        <button
          className="modal-close-button uk-modal-close-default uk-close uk-icon"
          type="button"
          onClick={() => setErrorModalOpen(false)}
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              fill="none"
              stroke="#000"
              strokeWidth="1.1"
              x1="1"
              y1="1"
              x2="13"
              y2="13"
            ></line>
            <line
              fill="none"
              stroke="#000"
              strokeWidth="1.1"
              x1="13"
              y1="1"
              x2="1"
              y2="13"
            ></line>
          </svg>
        </button>
        <div className="uk-modal-header">
          <h2 className="uk-modal-title">{errorMessageHeading}</h2>
        </div>
        <div
          className="uk-modal-body react-modalcontent"
          uk-overflow-auto="true"
          //dangerouslySetInnerHTML={{ __html: t('imprint') }}
        >
          {errorMessageBody}
        </div>
        <div className="uk-modal-footer uk-text-center">
          <button
            className="uk-button uk-button-default uk-modal-close"
            onClick={() => setErrorModalOpen(false)}
          >
            {t('success_close')}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ErrorModal;
