import { t } from 'i18next';
import React from 'react';
import Modal from 'react-modal';

interface IImprintModal {
  imprintIsOpen: boolean;
  setImprintOpen: React.Dispatch<React.SetStateAction<boolean>>;
  t: any;
}

const ImprintModal: React.FC<IImprintModal> = ({
  imprintIsOpen,
  setImprintOpen
}) => {
  return (
    <Modal
      isOpen={imprintIsOpen}
      overlayClassName="Overlay"
      className="react-modal"
      ariaHideApp={false}
    >
      <div>
        <button
          className="modal-close-button uk-modal-close-default uk-close uk-icon"
          type="button"
          onClick={() => setImprintOpen(false)}
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              fill="none"
              stroke="#000"
              strokeWidth="1.1"
              x1="1"
              y1="1"
              x2="13"
              y2="13"
            ></line>
            <line
              fill="none"
              stroke="#000"
              strokeWidth="1.1"
              x1="13"
              y1="1"
              x2="1"
              y2="13"
            ></line>
          </svg>
        </button>
        <div className="uk-modal-header">
          <h2 className="uk-modal-title">
            {t('footer.site_notice.title') as string}
          </h2>
        </div>
        <div
          className="uk-modal-body react-modalcontent"
          uk-overflow-auto="true"
          dangerouslySetInnerHTML={{ __html: t('imprint') }}
        ></div>
        <div className="uk-modal-footer uk-text-center">
          <button
            className="uk-button uk-button-default uk-modal-close"
            onClick={() => setImprintOpen(false)}
          >
            {t('success_close') as string}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ImprintModal;
