import { useState } from 'react';
import FormInput from './formInput';
import LoadingSpinner from './loadingSpinner';
import { validateApolloId } from '../utils/validation';
import { IApolloBody, ICumulocityBody } from '../App';

interface IApolloForm {
  loading: boolean;
  setApolloId: React.Dispatch<React.SetStateAction<string>>;
  handleFormSubmit: (body: IApolloBody | ICumulocityBody) => Promise<void>;
  apolloId: string;
  t: any;
  imei: string;
  customerId: string;
  domain: string;
  currentLanguage: string;
}

const ApolloForm: React.FC<IApolloForm> = ({
  loading,
  setApolloId,
  handleFormSubmit,
  apolloId,
  imei,
  customerId,
  domain,
  currentLanguage,
  t
}) => {
  const [apolloIdValidated, setApolloIdValidated] = useState<boolean>(true);
  const [apolloIdEmpty, setApolloIdEmpty] = useState<boolean>(false);
  const [apolloFormValidated, setApolloFormValidated] = useState<boolean>(true);

  const handleFormValidationOnSubmit = (e: any) => {
    let formValid: boolean = true;

    let apolloIdValid: boolean = true;

    e.preventDefault();
    if (apolloId) {
      formValid = true;

      apolloIdValid = validateApolloId(apolloId).validated;
      setApolloIdValidated(apolloIdValid);
    } else {
      formValid = false;
      setApolloFormValidated(false);
      setApolloIdEmpty(true);
    }

    if (!apolloIdValid) {
      formValid = false;
      setApolloFormValidated(false);
    }

    if (formValid === true) {
      let body: IApolloBody = {
        imei: imei,
        customerId: customerId,
        domain: domain,
        apolloId: apolloId,
        language: currentLanguage
      };
      handleFormSubmit(body);
    }
  };

  return (
    <form
      id="apolloForm"
      className="uk-padding-large uk-grid-small uk-padding-remove-top"
      uk-grid="true"
    >
      <div className="uk-width-1-1 uk-margin-small-bottom">
        <h3>
          <b>{t('apollo.heading')}</b>
        </h3>
      </div>

      <div className="uk-width-1-1 as-url-input-wrapper">
        <label htmlFor="form-url">
          {t('apollo.subheading')}
          <br />
        </label>

        <div className="uk-margin uk-grid-collapse" uk-grid="true">
          <div className="uk-width-1-1">
            <FormInput
              setEmpty={setApolloIdEmpty}
              isEmpty={apolloIdEmpty}
              validationFct={validateApolloId}
              isValidated={apolloIdValidated}
              setValidated={setApolloIdValidated}
              input={apolloId}
              id="form-url"
              name="url"
              type="text"
              className="
                      uk-input
                      as-input
                      uk-form-large
                      uk-text-center
                      uk-text-small
                      uk-border-rounded
                      uk-background-muted
                    "
              placeholder={t('apollo.placeholder')}
              autoComplete="off"
              required
              maxLength={36}
              pattern="[a-z][a-zA-Z0-9-]*"
              onChange={(e: any) => setApolloId(e.currentTarget.value)}
            />
          </div>
        </div>
      </div>

      <div className="uk-width-1-1 uk-margin-medium-top">
        <div className="uk-center-text">
          <button
            type="submit"
            className="
                      uk-button uk-button-primary
                      as-button-primary
                      uk-button-large uk-border-rounded
                      as-hide-if-loading
                    "
            onClick={(e) => handleFormValidationOnSubmit(e)}
          >
            {loading ? <LoadingSpinner /> : <p>{t('apollo.button')}</p>}
          </button>
        </div>
        <div>
          {apolloFormValidated ? (
            <div></div>
          ) : (
            <label className="uk-text-danger" style={{ fontSize: '80%' }}>
              {t('errors.form')}
            </label>
          )}
        </div>
        <div>
          {loading ? (
            <div className="uk-margin-small-top">
              <label style={{ fontSize: '80%' }}>{t('form.lineone')}</label>
              <label style={{ fontSize: '80%' }}>{t('form.linetwo')}</label>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </form>
  );
};

export default ApolloForm;
