import React from 'react';
import { useTranslation } from 'react-i18next';

interface ISelectValidation {
  setValue: React.Dispatch<React.SetStateAction<string>>;
  options: string[];
  fullWidth?: boolean;
  isValid: boolean;
}

const SelectValidation = ({
  setValue,
  options,
  fullWidth = false,
  isValid
}: ISelectValidation) => {
  const { t } = useTranslation();
  const errMessage = t('validation.required');
  const newOptions = [...options];
  const disabledOption = options[0];
  newOptions.shift();

  return (
    <div>
      <div
        {...(fullWidth
          ? { style: { width: '100%', maxWidth: '840px' } }
          : { style: { width: '100%', maxWidth: '412.5px' } })}
      >
        <div {...(fullWidth ? { className: 'uk-margin' } : { className: '' })}>
          <select
            defaultValue={disabledOption}
            onChange={(e) => {
              setValue(e.currentTarget.value);
            }}
            name="salutation"
            //type="text"
            className="
                    uk-select
                    as-select
                    uk-form-large
                    uk-text-small
                    uk-border-rounded
                    uk-background-muted
                  "
            required
          >
            <option key={disabledOption} disabled>
              {disabledOption}
            </option>
            {newOptions.map((option: string, idx) => (
              <option key={idx}>{option}</option>
            ))}
          </select>

          <div>
            {isValid ? (
              <></>
            ) : (
              <label className="uk-text-danger" style={{ fontSize: '80%' }}>
                {errMessage}
              </label>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectValidation;
