import React from 'react';
import { useTranslation } from 'react-i18next';
import FormInput from './formInput';
import LoadingSpinner from './loadingSpinner';
import { useState } from 'react';
import {
  validateUrl,
  validateEmail,
  validatePassword,
  validatePasswordConfirm,
  validateAcceptToc,
  validatePostcode,
  validatePhone
} from '../utils/validation';
import TocInput from './tocInput';
import { IApolloBody, ICumulocityBody } from '../App';
import RequiredInputField from './requiredInputField';
import SelectValidation from './selectValidation';

interface IConctactDataForm {
  setSalutation: React.Dispatch<React.SetStateAction<string>>;
  setFirstName: React.Dispatch<React.SetStateAction<string>>;
  setLastName: React.Dispatch<React.SetStateAction<string>>;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  setCompany: React.Dispatch<React.SetStateAction<string>>;
  setIndustry: React.Dispatch<React.SetStateAction<string>>;
  setPhone: React.Dispatch<React.SetStateAction<string>>;
  setStreet: React.Dispatch<React.SetStateAction<string>>;
  setPostcode: React.Dispatch<React.SetStateAction<string>>;
  setCity: React.Dispatch<React.SetStateAction<string>>;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  setPasswordConfirm: React.Dispatch<React.SetStateAction<string>>;
  setAcceptToc: React.Dispatch<React.SetStateAction<boolean>>;
  setAcceptNewsletter: React.Dispatch<React.SetStateAction<boolean>>;
  acceptToc: boolean;
  acceptNewsletter: boolean;
  handleFormSubmit: (body: IApolloBody | ICumulocityBody) => Promise<void>;
  loading: boolean;
  email: string;
  password: string;
  passwordConfirm: string;
  salutation: string;
  firstName: string;
  lastName: string;
  company: string;
  industry: string;
  phone: string;
  street: string;
  postcode: string;
  city: string;
  currentLanguage: string;
  url: string;
  setUrl: React.Dispatch<React.SetStateAction<string>>;
  imei: string;
  customerId: string;
  handleNewsletterSubscription: () => Promise<void>;
}

const ContactDataForm: React.FC<IConctactDataForm> = ({
  imei,
  customerId,
  setSalutation,
  setFirstName,
  setLastName,
  setEmail,
  setCompany,
  setIndustry,
  setPhone,
  setStreet,
  setPostcode,
  setCity,
  setPassword,
  setPasswordConfirm,
  setAcceptToc,
  setAcceptNewsletter,
  acceptToc,
  acceptNewsletter,
  handleFormSubmit,
  loading,
  email,
  password,
  passwordConfirm,
  salutation,
  firstName,
  lastName,
  company,
  industry,
  phone,
  street,
  postcode,
  city,
  currentLanguage,
  setUrl,
  url,
  handleNewsletterSubscription
}) => {
  const { t } = useTranslation();

  //Form validation state
  const [firstNameEmpty, setFirstNameEmpty] = useState<boolean>(false);
  const [lastNameEmpty, setLastNameEmpty] = useState<boolean>(false);
  const [companyEmpty, setCompanyEmpty] = useState<boolean>(false);
  const [phoneEmpty, setPhoneEmpty] = useState<boolean>(false);
  const [streetEmpty, setStreetEmpty] = useState<boolean>(false);
  const [cityEmpty, setCityEmpty] = useState<boolean>(false);
  const [urlEmpty, setUrlEmpty] = useState<boolean>(false);
  const [emailEmpty, setEmailEmpty] = useState<boolean>(false);
  const [postcodeEmpty, setPostcodeEmpty] = useState<boolean>(false);
  const [passwordEmpty, setPasswordEmpty] = useState<boolean>(false);
  const [passwordConfirmEmpty, setPasswordConfirmEmpty] =
    useState<boolean>(false);

  const [salutationValidated, setSalutationValidated] = useState<boolean>(true);
  const [industryValidated, setIndustryValidated] = useState<boolean>(true);
  const [urlValidated, setUrlValidated] = useState<boolean>(true);
  const [emailValidated, setEmailValidated] = useState<boolean>(true);
  const [postcodeValidated, setPostcodeValidated] = useState<boolean>(true);
  const [passwordValidated, setPasswordValidated] = useState<boolean>(true);
  const [passwordConfirmValidated, setPasswordConfirmValidated] =
    useState<boolean>(true);
  const [tocValidated, setTocValidated] = useState<boolean>(true);
  const [contactFormValidated, setContactFormValidated] =
    useState<boolean>(true);
  const [phoneValidated, setPhoneValidated] = useState<boolean>(true);

  const industryOptions = [t('form.industry.label')].concat(
    t<string, Array<string>>('form.industry.options', {
      returnObjects: true
    })
  );

  const salutationOptions = [
    t('form.salutation.label'),
    t('form.salutation.mrs'),
    t('form.salutation.mr')
  ];

  const getFormUrlSmallprintLink = () => {
    return `img/lang/` + currentLanguage + '/url.jpg';
  };

  const handleFormValidation = (e: any) => {
    let formValid: boolean = true;

    let tocValid: boolean = true;
    let urlValid: boolean = true;
    let emailValid: boolean = true;
    let passwordValid: boolean = true;
    let postcodeValid: boolean = true;
    let passwordConfirmValid: boolean = true;
    let phoneValid = true;

    e.preventDefault();
    if (!acceptToc) {
      formValid = false;
      setTocValidated(false);
    }
    if (
      email &&
      password &&
      passwordConfirm &&
      acceptToc &&
      postcode &&
      url &&
      phone
    ) {
      formValid = true;
      setContactFormValidated(true);

      //Check if inputs are validated
      tocValid = validateAcceptToc(acceptToc).validated;
      setTocValidated(tocValid);

      urlValid = validateUrl(url).validated;
      setUrlValidated(urlValid);

      emailValid = validateEmail(email).validated;
      setEmailValidated(emailValid);

      passwordValid = validatePassword(password).validated;
      setPasswordValidated(passwordValid);

      postcodeValid = validatePostcode(postcode).validated;
      setPostcodeValidated(postcodeValid);

      passwordConfirmValid = validatePasswordConfirm({
        password,
        passwordConfirm
      }).validated;
      setPasswordConfirmValidated(passwordConfirmValid);

      phoneValid = validatePhone(phone).validated;
      setPhoneValidated(phoneValid);
    } else {
      formValid = false;
      setContactFormValidated(false);

      if (!email) {
        setEmailEmpty(true);
      }
      if (!password) {
        setPasswordEmpty(true);
      }
      if (!passwordConfirm) {
        setPasswordConfirmEmpty(true);
      }
      if (!url) {
        setUrlEmpty(true);
      }
      if (!postcode) {
        setPostcodeEmpty(true);
      }
      if (!firstName) {
        setFirstNameEmpty(true);
      }
      if (!lastName) {
        setLastNameEmpty(true);
      }
      if (!company) {
        setCompanyEmpty(true);
      }
      if (!phone) {
        setPhoneEmpty(true);
      }
      if (!street) {
        setStreetEmpty(true);
      }
      if (!city) {
        setCityEmpty(true);
      }
    }

    if (salutation.length < 1) {
      formValid = false;
      setSalutationValidated(false);
    }

    if (industry.length < 1) {
      formValid = false;
      setIndustryValidated(false);
    }

    //If something was not validated or is missing set global validation to false
    if (
      !tocValid ||
      !passwordValid ||
      !passwordConfirmValid ||
      !tocValid ||
      !postcodeValid ||
      !phoneValid ||
      !urlValid ||
      !emailValid ||
      !salutation ||
      !firstName ||
      !lastName ||
      !industry ||
      !company ||
      !phone ||
      !street ||
      !city ||
      !acceptToc
    ) {
      formValid = false;
      setContactFormValidated(false);
    }

    if (formValid === true) {
      const body: ICumulocityBody = {
        imei: imei,
        customerId: customerId,
        domain: url,
        email: email,
        salutation: salutation,
        firstName: firstName,
        lastName: lastName,
        company: company,
        phone: phone,
        street: street,
        postcode: postcode,
        city: city,
        industry: industry,
        password: password,
        language: currentLanguage
      };

      handleFormSubmit(body);

      if (acceptNewsletter) {
        handleNewsletterSubscription();
      }
    }
  };

  return (
    <form
      id="contactForm"
      className="uk-padding-large uk-grid-small uk-padding-remove-top"
      uk-grid="true"
    >
      <div className="uk-width-1-1 as-url-input-wrapper">
        <label htmlFor="form-url">
          {t('form.url.label')}
          <br />
          <div uk-lightbox="true">
            <a
              href={getFormUrlSmallprintLink()}
              className="uk-link-muted"
              data-caption={t('form.url.smallprint')}
            >
              <small className="uk-text-muted" style={{ fontWeight: 'bold' }}>
                {t('form.url.smallprint')}
              </small>
            </a>
          </div>
        </label>

        <div className="uk-margin uk-grid-collapse" uk-grid="true">
          <div className="uk-width-1-2@s uk-width-3-4@m">
            <FormInput
              isEmpty={urlEmpty}
              setEmpty={setUrlEmpty}
              validationFct={validateUrl}
              input={url}
              id="form-url"
              name="url"
              type="text"
              className="uk-input
            as-input
            uk-form-large
            uk-text-center
            uk-text-small
            uk-border-rounded
            uk-background-muted"
              autoComplete="off"
              required
              placeholder={t('form.url.placeholder')}
              maxLength={20}
              pattern="[a-z][a-zA-Z0-9-]*"
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setUrl(e.currentTarget.value)
              }
              isValidated={urlValidated}
              setValidated={setUrlValidated}
            />
          </div>

          <div className="uk-width-1-2@s uk-width-1-4@m">
            <input
              type="text"
              className="
                        uk-input
                        uk-border-rounded
                        uk-text-small
                        uk-text-center
                        uk-form-large
                      "
              value=".autosen.cloud"
              readOnly
              disabled
            />
          </div>
        </div>
      </div>

      <div className="uk-width-1-1 uk-margin-small-bottom uk-margin-large-top">
        <h3>
          <b>{t('form.title_credentials')}</b>
        </h3>
      </div>

      <div className="uk-width-1-1 uk-flex uk-flex-bottom uk-flex-right ">
        <div className="uk-width">
          <SelectValidation
            setValue={setSalutation}
            options={salutationOptions}
            isValid={salutationValidated}
          />
        </div>
        <div className="uk-margin uk-text-right uk-text-small uk-margin-left">
          {t('form.required_field')}
        </div>
      </div>

      <div className="uk-width-1-2">
        <div className="uk-margin">
          <RequiredInputField
            setEmpty={setFirstNameEmpty}
            isEmpty={firstNameEmpty}
            input={firstName}
            name="firstName"
            type="text"
            className="
                      uk-input
                      as-input
                      uk-form-large
                      uk-text-small
                      uk-border-rounded
                      uk-background-muted
                    "
            placeholder={t('form.first_name')}
            autoComplete="off"
            required
            maxLength={20}
            pattern="[a-zA-Z]*"
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              setFirstName(e.currentTarget.value)
            }
          />
        </div>
      </div>

      <div className="uk-width-1-2">
        <div className="uk-margin">
          <RequiredInputField
            input={lastName}
            setEmpty={setLastNameEmpty}
            isEmpty={lastNameEmpty}
            name="lastName"
            type="text"
            className="
                      uk-input
                      as-input
                      uk-form-large
                      uk-text-small
                      uk-border-rounded
                      uk-background-muted
                    "
            placeholder={t('form.last_name')}
            autoComplete="off"
            required
            maxLength={20}
            pattern="[a-zA-Z]*"
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              setLastName(e.currentTarget.value)
            }
          />
        </div>
      </div>

      <div className="uk-width-1-1">
        <label htmlFor="email">
          <div uk-lightbox="true">
            <small
              className="uk-text-muted"
              style={{ textDecoration: 'none !important' }}
            >
              {t('form.email.smallprint')}
            </small>
          </div>
        </label>
        <div className="uk-margin">
          <FormInput
            setEmpty={setEmailEmpty}
            isEmpty={emailEmpty}
            input={email}
            isValidated={emailValidated}
            setValidated={setEmailValidated}
            validationFct={validateEmail}
            style={{ fontWeight: 'bold' }}
            name="email"
            type="email"
            className="
                    uk-input
                    as-input
                    uk-form-large
                    uk-text-small
                    uk-border-rounded
                    uk-background-muted
                  "
            placeholder={t('form.email.placeholder')}
            autoComplete="off"
            required
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              setEmail(e.currentTarget.value)
            }
          />
        </div>
      </div>

      <div className="uk-width-1-1">
        <div className="uk-margin">
          <RequiredInputField
            input={company}
            setEmpty={setCompanyEmpty}
            isEmpty={companyEmpty}
            name="company"
            type="text"
            className="
                      uk-input
                      as-input
                      uk-form-large
                      uk-text-small
                      uk-border-rounded
                      uk-background-muted
                    "
            placeholder={t('form.company')}
            autoComplete="off"
            maxLength={256}
            required
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              setCompany(e.currentTarget.value)
            }
          />
        </div>
      </div>

      <div className="uk-width-1-1" style={{ marginBottom: '-20px' }}>
        <SelectValidation
          options={industryOptions}
          setValue={setIndustry}
          fullWidth
          isValid={industryValidated}
        />
      </div>

      <div className="uk-width-1-1">
        <div className="uk-margin">
          <FormInput
            isValidated={phoneValidated}
            setValidated={setPhoneValidated}
            validationFct={validatePhone}
            input={phone}
            setEmpty={setPhoneEmpty}
            isEmpty={phoneEmpty}
            name="phone"
            type="tel"
            className="
                    uk-input
                    as-input
                    uk-form-large
                    uk-text-small
                    uk-border-rounded
                    uk-background-muted
                  "
            placeholder={t('form.phone')}
            autoComplete="off"
            required
            maxLength={20}
            pattern="[+](([(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[\s\.]?[(]?[0-9]{1,3}[)]?([\s\.]?[0-9]{3})([\s\.]?[0-9]{3,4})"
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              setPhone(e.currentTarget.value)
            }
          />
        </div>
      </div>

      <div className="uk-width-1-1">
        <div className="uk-margin">
          <RequiredInputField
            input={street}
            setEmpty={setStreetEmpty}
            isEmpty={streetEmpty}
            name="street"
            type="text"
            className="
                      uk-input
                      as-input
                      uk-form-large
                      uk-text-small
                      uk-border-rounded
                      uk-background-muted
                    "
            placeholder={t('form.street')}
            autoComplete="off"
            required
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              setStreet(e.currentTarget.value)
            }
            maxLength={200}
          />
        </div>
      </div>

      <div className="uk-width-1-3">
        <div className="uk-margin">
          <FormInput
            setEmpty={setPostcodeEmpty}
            isEmpty={postcodeEmpty}
            validationFct={validatePostcode}
            isValidated={postcodeValidated}
            setValidated={setPostcodeValidated}
            input={postcode}
            name="postcode"
            type="text"
            className="
                      uk-input
                      as-input
                      uk-form-large
                      uk-text-small
                      uk-border-rounded
                      uk-background-muted
                    "
            placeholder={t('form.postcode')}
            autoComplete="off"
            required
            maxLength={20}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              setPostcode(e.currentTarget.value)
            }
          />
        </div>
      </div>

      <div className="uk-width-2-3">
        <div className="uk-margin">
          <RequiredInputField
            input={city}
            isEmpty={cityEmpty}
            setEmpty={setCityEmpty}
            name="city"
            type="text"
            className="
                      uk-input
                      as-input
                      uk-form-large
                      uk-text-small
                      uk-border-rounded
                      uk-background-muted
                    "
            placeholder={t('form.city')}
            autoComplete="off"
            required
            maxLength={50}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              setCity(e.currentTarget.value)
            }
          />
        </div>
      </div>

      <div className="uk-width-1-2">
        <div className="uk-margin">
          <FormInput
            setEmpty={setPasswordEmpty}
            isEmpty={passwordEmpty}
            validationFct={validatePassword}
            isValidated={passwordValidated}
            setValidated={setPasswordValidated}
            input={password}
            id="form-password"
            name="password"
            type="password"
            className="
                      uk-input
                      as-input
                      uk-form-large
                      uk-text-small
                      uk-border-rounded
                      uk-background-muted
                    "
            placeholder={t('form.password')}
            autoComplete="off"
            required
            minLength={6}
            maxLength={20}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              setPassword(e.currentTarget.value)
            }
          />
        </div>
      </div>

      <div className="uk-width-1-2">
        <div className="uk-margin">
          <FormInput
            setEmpty={setPasswordConfirmEmpty}
            isEmpty={passwordConfirmEmpty}
            input={passwordConfirm}
            passwordInput={password}
            isValidated={passwordConfirmValidated}
            setValidated={setPasswordConfirmValidated}
            validationFct={validatePasswordConfirm}
            type="password"
            className="
                    uk-input
                    as-input
                    uk-form-large
                    uk-text-small
                    uk-border-rounded
                    uk-background-muted
                  "
            placeholder={t('form.password_confirmation')}
            required
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              setPasswordConfirm(e.currentTarget.value)
            }
          />
        </div>
      </div>

      <div className="uk-width-1-1">
        <div className="uk-margin-medium-top uk-text-left uk-text-small">
          <label className="uk-flex uk-flex-middle">
            <TocInput
              isValidated={tocValidated}
              setValidated={setTocValidated}
              input={acceptToc}
              validationFct={validateAcceptToc}
              name="acceptToc"
              type="checkbox"
              className="
                      uk-checkbox
                      as-checkbox-primary
                      uk-form-large
                      uk-text-small
                      uk-border-rounded
                      uk-background-muted
                    "
              required
              onChange={() => setAcceptToc(!acceptToc)}
            />
          </label>
        </div>
      </div>

      <input
        name="acceptNewsletter"
        type="checkbox"
        className="
                  uk-checkbox
                  as-checkbox-primary
                  uk-form-large
                  uk-text-small
                  uk-border-rounded
                  uk-background-muted
                "
        required
        //checked
        defaultChecked
        hidden
      />

      <div className="uk-width-1-1">
        <div className="uk-margin-medium-top uk-text-left uk-text-small">
          <label className="uk-flex uk-flex-top">
            <input
              name="acceptPrivacy"
              type="checkbox"
              className="
                        uk-checkbox
                        as-checkbox-primary
                        uk-form-large
                        uk-text-small
                        uk-border-rounded
                        uk-background-muted
                      "
              onChange={() => setAcceptNewsletter(!acceptNewsletter)}
            />
            <div className="uk-margin-medium-left">
              <div
                className="text"
                dangerouslySetInnerHTML={{
                  __html: t('form.accept_privacy')
                }}
              ></div>
            </div>
          </label>
        </div>
      </div>

      <div className="uk-width-1-1 uk-margin-medium-top">
        <div className="uk-center-text">
          <button
            type="submit"
            className="
                      uk-button uk-button-primary
                      as-button-primary
                      uk-button-large uk-border-rounded
                      as-hide-if-loading
                    "
            onClick={(e) => handleFormValidation(e)}
          >
            {loading ? <LoadingSpinner /> : t('form.submit')}
          </button>
        </div>
        <div>
          {contactFormValidated ? (
            <div></div>
          ) : (
            <label className="uk-text-danger" style={{ fontSize: '80%' }}>
              {t('errors.form')}
            </label>
          )}
        </div>
        <div>
          {loading ? (
            <div className="uk-margin-small-top">
              <label style={{ fontSize: '80%' }}>{t('form.lineone')}</label>
              <label style={{ fontSize: '80%' }}>{t('form.linetwo')}</label>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </form>
  );
};

export default ContactDataForm;
